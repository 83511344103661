import { Link } from 'gatsby';
import Img from 'gatsby-image';
import _ from 'lodash';
import { lighten } from 'polished';
import React from 'react';
import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { colors } from '../styles/colors';
import { PageContext } from '../templates/post';

export interface PostCardProps {
    post: PageContext;
    large?: boolean;
}

export const PostCard: React.FC<PostCardProps> = ({ post, large = false }) => {

    return (
        <article
            className={`post-card ${post.frontmatter.image ? '' : 'no-image'} ${
                large ? 'post-card-large' : ''
                }`}
            css={[PostCardStyles, large && PostCardLarge]}
        >
            {post.frontmatter.image && (
                <Link className="post-card-image-link" css={PostCardImageLink} to={post.fields.slug}>
                    <PostCardImage className="post-card-image">
                        {post.frontmatter?.image?.childImageSharp?.fluid && (
                            <Img
                                alt={`${post.frontmatter.title} cover image`}
                                style={{ height: '100%' }}
                                fluid={post.frontmatter.image.childImageSharp.fluid}
                            />
                        )}
                    </PostCardImage>
                </Link>
            )}
            <PostCardContent className="post-card-content">
                <Link className="post-card-content-link" css={PostCardContentLink} to={post.fields.slug}>
                    &nbsp;
                    <PostCardHeader className="post-card-header">
                        <PostCardTitle className="post-card-title">{post.frontmatter.title}</PostCardTitle>
                    </PostCardHeader>
                    <PostCardExcerpt className="post-card-excerpt">
                        <p>{post.frontmatter.excerpt || post.excerpt}</p>
                    </PostCardExcerpt>
                </Link>
            </PostCardContent>
        </article>
    );
};

const PostCardStyles = css`
  position: relative;
  flex: 1 1 301px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  margin: 0 0 40px;
  padding: 0 20px 40px;
  min-height: 220px;
  background-size: cover;
`;

const PostCardLarge = css`
  @media (min-width: 795px) {
    flex: 1 1 100%;
    flex-direction: row;
    padding-bottom: 40px;
    min-height: 280px;
    border-top: 0;

    :not(.no-image) .post-card-header {
      margin-top: 0;
    }

    .post-card-image-link {
      position: relative;
      flex: 1 1 auto;
      margin-bottom: 0;
      min-height: 380px;
    }

    .post-card-image {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    .post-card-content {
      flex: 0 1 361px;
      justify-content: center;
    }

    .post-card-title {
      margin-top: 0;
      font-size: 3.2rem;
    }

    .post-card-content-link {
      padding: 0 0 0 40px;
    }

    .post-card-meta {
      padding: 0 0 0 40px;
    }

    .post-card-excerpt p {
      margin-bottom: 1.5em;
      font-size: 1.8rem;
      line-height: 1.5em;
    }
  }
`;

const PostCardImageLink = css`
  position: relative;
  display: block;
  overflow: hidden;
  border-radius: 5px 5px 0 0;
`;

const PostCardImage = styled.div`
  width: auto;
  height: 200px;
  background: ${colors.lightgrey} no-repeat center center;
  background-size: cover;
`;

const PostCardContent = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`;

const PostCardContentLink = css`
  position: relative;
  display: block;
  /* color: var(--darkgrey); */
  color: ${colors.darkgrey};

  :hover {
    text-decoration: none;
  }
`;

const PostCardTitle = styled.h2`
  margin: 0 0 0.4em;
  line-height: 1.15em;
  transition: color 0.2s ease-in-out;

  @media (prefers-color-scheme: dark) {
    color: rgba(255, 255, 255, 0.85);
  }
`;

const PostCardExcerpt = styled.section`
    color: gray;
`;

const PostCardHeader = styled.header`
  margin: 15px 0 0;
`;

export const StaticAvatar = css`
  display: block;
  overflow: hidden;
  margin: 0 0 0 -6px;
  width: 34px;
  height: 34px;
  border: #fff 2px solid;
  border-radius: 100%;

  @media (prefers-color-scheme: dark) {
    /* border-color: color(var(--darkgrey) l(+2%)); */
    border-color: ${lighten('0.02', colors.darkgrey)};
  }
`;

export const AuthorProfileImage = css`
  display: block;
  width: 100%;
  height: 100%;
  /* background: color(var(--lightgrey) l(+10%)); */
  background: ${lighten('0.1', colors.lightgrey)};
  border-radius: 100%;
  object-fit: cover;

  @media (prefers-color-scheme: dark) {
    background: ${colors.darkmode};
  }
`;
